import React, { useCallback, useEffect, useState } from 'react'
import Context from '~/context/StoreContext'
import * as localForage from 'localforage'
import { gql } from '@apollo/client'
import { shopifyClient } from '~/lib/shopify'
import { trackAddToCart } from '~/utils/tracking'
import { 
  createCartMutation,
  addCartLinesMutation, 
  updateCartLinesMutation,
  removeCartLinesMutation,
  setCartAttributesMutation 
} from '../lib/shopify/mutations/cart'
import { fetchCartQuery } from '../lib/shopify/queries/cart'

const isBrowser = typeof window !== 'undefined'

export const ContextProvider = ({ children }) => {
  let initialStoreState = {
    shopifyClient,
    fetching: true,
    adding: false,
    updating: false,
    hasMto: false,
    hasSuit: false,
    silent: false,
    cartError: null,
    cart: {},
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    extraAttributes: {},
  }

  const [store, updateStore] = useState(initialStoreState)

  /**
   * Create a new cart in Shopify
   * 
   * @returns {Promise<Object>} The cartP
   */
  const createCart = async (lines) => {
    return shopifyClient.mutate({
      mutation: gql`${createCartMutation}`,
      variables: { 
        cartInput: { 
          lines
        } 
      }
    }).then((res) => {
      setCartInState(res.data.cartCreate.cart)
      applyGorgiasAttributes(res?.data?.cartCreate?.cart?.id)
      return res.data.cartCreate.cart
    }).catch((error) => {
      console.log(error)
      return null
    })
  }

  /**
   * Fetch an existing cart from Shopify
   * 
   * @returns {Promise<Object>} The cart
   */
  const fetchCart = useCallback(async (cartId) => {
    return shopifyClient.query({
      query: gql`${fetchCartQuery}`,
      variables: { cartId }
    }
    ).then((res) => {
      setCartInState(res?.data?.cart ?? {})
      return res.data.cart
    }).catch((error) => {
      console.log(error)
      return null
    })
  }, [])

  /**
   * Get the current custom attributes from the checkout
   * Map from GraphModel to regular object
   *
   * @returns {Array} The attributes
   */
  const getCurrentCustomAttributes = () => {
    return (
      store?.cart?.attributes.map(attr => ({
        key: attr.key,
        value: attr.value,
      })) ?? []
    )
  }

  /**
   * Check if the title contains a suit word
   * 
   * @param {String} title
   * @returns {Boolean} Whether the title contains a suit word
   */
  const hasSuitTitle = (title) => {

    const suitWords = ['Jacket', 'Pant', 'Vest', 'Dress', 'Skirt', 'Short'];

    return title !== '' ? suitWords.some(word => title.includes(word)) : false
  }

  /**
   * Merge new custom attributes with the current ones
   * Remove any duplicates
   *
   * @param {Array} attributes Incoming, new attributes
   * @returns {Array} The merged attributes
   */
  const mergeCustomAttributes = attributes => {
    const currentAttributes = getCurrentCustomAttributes()
    const newAttributes = [...currentAttributes, ...(attributes ?? [])]

    const keys = newAttributes.map(({ key }) => key)
    return newAttributes.filter(
      ({ key }, index) => !keys.includes(key, index + 1)
    )
  }

  /**
   * Apply Gorgias attributes to the checkout
   * This is used to surface data for Gorgias Convert
   * 
   * There were issues creating a cart with attributes, so we're updating them after the fact
   * 
   * @param {String} cartId The cart ID
   * @returns {void}
   */
  const applyGorgiasAttributes = async (cartId) => {
    const initializeGorgias = window.GorgiasBridge
      ? window.GorgiasBridge.init()
      : new Promise(function (resolve, reject) {
          const timer = setTimeout(() => reject(), 500)
          window.addEventListener('gorgias-bridge-loaded', () => {
            clearTimeout(timer)
            resolve()
          })
        })

    initializeGorgias
      .then(async () => {
        const attributes = window.GorgiasBridge.createCheckoutAttributes() ?? []

        if (cartId && attributes.length > 0) {
          shopifyClient.mutate({
            mutation: gql`${setCartAttributesMutation}`,
            variables: { 
              cartId, 
              attributes
            }
          }).then((res) => {
            setCartInState(res?.data?.cartAttributesUpdate?.cart)
            return res?.data?.cartAttributesUpdate?.cart
          }).catch((error) => {
            console.log(error)
            return null
          })
        }
      })
      .catch(() => console.log('[GRG] Gorgias not found'))
  }

  /**
   * Set the cart in the state
   *
   * @param {Object} cart The cart object
   * @returns {void}
   */
  const setCartInState = cart => {
    if (isBrowser && cart?.id) {
      localStorage.setItem('shopify_cart_id', cart.id)
      updateStore(prevState => {
        return { ...prevState, cart, adding: false, fetching: false, updating: false, silent: false }
      })
    } else {
      localStorage.removeItem('shopify_cart_id')
      localForage.removeItem('suitshopExtraAttributes')
      updateStore(prevState => {
        return { ...prevState, cart: {}, adding: false, fetching: false, updating: false, silent: false }
      })
    }
  }

  /**
   * Track ATC for all lines
   *
   * @param {Array<Object>} lines
   * @returns {void}
   */
  const trackAddLines = (lines) => {
    for (let line of lines) {
      trackAddToCart(
        line.product, 
        [
          line.variant
        ],
        store.cart.id
      )
    }
  }

  useEffect(() => {
    const initializeCart = async () => { 
      const existingCartId = isBrowser
        ? localStorage.getItem('shopify_cart_id')
        : null
      
      if (existingCartId) {
        await fetchCart(existingCartId)
      } else {
        updateStore(prevState => {
          return { ...prevState, fetching: false }
        })
      }
    }

    initializeCart()
  }, [fetchCart])


  useEffect(() => {
    if (store?.cart?.lines?.edges.length > 0) {
      let hasMto = false
      let hasSuit = false

      for(let line of store.cart.lines.edges) {
        if (line?.node?.mtoTimeframe) {
          hasMto = true
        }

        if (hasSuitTitle(line?.node?.merchandise?.product?.title ?? '')) {
          hasSuit = true
        }
      }

      updateStore(prevState => {
        return { ...prevState, hasMto, hasSuit }
      })
    }
  }, [store.cart.lines])


  return (
    <Context.Provider
      value={{
        store,
        updateStore,

        /**
         * Add a variant to the cart
         * 
         * @param {Object} product
         * @param {Object} variant
         * @param {Int} quantity
         * @param {Array<Object>} customAttributes
         * @returns {Promise<Object>|Object} The updated cart
         */
        addToCart: async (
          lines,
          silent = false
        ) => {     
          if (!silent) {
            updateStore(prevState => {
              return { ...prevState, adding: true, updating: true }
            })
          }
      
          // If there's no cart, create one with our items
          if (!store?.cart?.id) {
            createCart(
              lines.map(line => ({
                merchandiseId: line.variant.shopifyId,
                quantity: line.quantity,
                attributes: line.customAttributes
              }))
            ).then((cart) => {
              trackAddLines(lines)
              return cart
            })
          // Otherwise, add items to an existing cart
          } else {
            shopifyClient.mutate({
              mutation: gql`${addCartLinesMutation}`,
              variables: { 
                cartId: store.cart.id, 
                lines: lines.map(line => ({
                  merchandiseId: line.variant.shopifyId,
                  quantity: line.quantity,
                  attributes: line.customAttributes
                }))
              }
            }).then((res) => {
              setCartInState(res.data.cartLinesAdd.cart)
              trackAddLines(lines)
              return res.data.cartLinesAdd.cart
            }).catch((error) => {
              console.log(error)
              return null
            })
          }

          return store.cart
        },

        /**
         * Fetch extra attributes
         * Deprecated for now and used directly in cart.js
         * 
         * @returns {Promise<void>}
         */
        fetchExtraAttributes: async () => {
          localForage.getItem('suitshopExtraAttributes').then(data => {
            updateStore(prevState => {
              return { ...prevState, extraAttributes: data ?? {} }
            })
          })
        },

        /**
         * Set extra attributes
         * 
         * @param {Object} attributes
         * @returns {Promise<void>}
         */
        setExtraAttributes: (attributes) => {
          localForage.setItem('suitshopExtraAttributes', attributes)
          updateStore(prevState => {
            return { ...prevState, extraAttributes: attributes }
          })
        },

        /**
         * Update the cart's line items
         * 
         * @param {Array<Object>} lines 
         * @returns {Promise<Object>|Object} The updated cart
         */
        updateCartLines: async (lines) => {
          updateStore(prevState => {
            return { ...prevState, updating: true }
          })

          shopifyClient.mutate({
            mutation: gql`${updateCartLinesMutation}`,
            variables: { 
              cartId: store.cart.id, 
              lines: lines
            }
          }).then((res) => {
            setCartInState(res.data.cartLinesUpdate.cart)
            return res.data.cartLinesUpdate.cart
          }).catch((error) => {
            console.log(error)
            updateStore(prevState => {
              return { ...prevState, adding: false, updating: false }
            })
            return null
          })

          return store.cart
        },

        /**
         * Remove line items from the cart
         * 
         * @param {Array<String>} lineIds
         * @returns {Promise<Object>|Object} The updated cart
         */
        removeCartLines: async (lineIds) => {
          updateStore(prevState => {
            return { ...prevState, updating: true }
          })

          shopifyClient.mutate({
            mutation: gql`${removeCartLinesMutation}`,
            variables: { 
              cartId: store.cart.id, 
              lineIds
            }
          }).then((res) => {
            setCartInState(res.data.cartLinesRemove.cart)
            return res.data.cartLinesRemove.cart
          }).catch((error) => {
            console.log(error)
            updateStore(prevState => {
              return { ...prevState, updating: false }
            })
            return null
          })

          return store.cart
        },

        /**
         * Remove error message from cart
         *
         * @returns {void}
         */
        removeCartError: () => {
          updateStore(prevState => {
            return { ...prevState, cartError: null }
          })
        },

        /**
         * Update the cart's attributes
         *
         * @param {Object} client
         * @param {String} cartId
         * @param {Array<Object>} attributes
         * @returns {Promise<Object>|Object} The updated checkout
         */
        updateCartAttributes: async (cartId, attributes, silent = false) => {
          const newAttributes = mergeCustomAttributes(attributes)

          updateStore(prevState => {
            return { ...prevState, updating: true, silent: silent }
          })

          return shopifyClient.mutate({
            mutation: gql`${setCartAttributesMutation}`,
            variables: { 
              cartId, 
              attributes: newAttributes
            }
          }).then((res) => {
            setCartInState(res?.data?.cartAttributesUpdate?.cart)
            return res?.data?.cartAttributesUpdate?.cart
          }).catch((error) => {
            console.log(error)
            updateStore(prevState => {
              return { ...prevState, updating: false, silent: false }
            })
            return null
          })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
