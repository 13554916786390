export const linkResolver = (doc) => {
    if (doc.type === 'blog_author') {
        return `/blogs/authors/${doc.uid}/`;
    }

    if (doc.type === 'blog_post') {
        return `/blogs/news/${doc.uid}/`;
    }

    if (doc.type === 'job_posting') {
        return `/careers/${doc.uid}/`;
    }

    if (doc.type === 'real_wedding') {
        return `/blogs/real-weddings/${doc.uid}/`;
    }

    return `/docs/${doc.uid}`;
}