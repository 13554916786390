import React from 'react'
import ShopMenu from '~/components/Navigation/MegaMenu/ShopMenu'
import GetStartedMenu from '~/components/Navigation/MegaMenu/GetStartedMenu'
import WeddingsEventsMenu from '~/components/Navigation/MegaMenu/WeddingsEventsMenu'
import ResourcesMenu from '~/components/Navigation/MegaMenu/ResourcesMenu'

const DynamicMegaMenu = ({ menuName, active }) => {
    const components = {
        'Shop': () => <ShopMenu isActive={active === 'Shop'} />,
        'GetStarted': () => <GetStartedMenu isActive={active === 'GetStarted'} />,
        'Weddings&Events': () => <WeddingsEventsMenu isActive={active === 'Weddings&Events'} />,
        'Resources': () => <ResourcesMenu isActive={active === 'Resources'} />,
    };

    const Component = components[menuName];

    return <>{Component ? <Component /> : null}</>;
}

export default DynamicMegaMenu