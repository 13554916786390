import React, { useContext } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import { getVariantTitle } from '../../../utils/helpers'
import { formatPrice } from '~/utils/format-price'
import {
  lineItemHasDiscount,
  lineItemDiscountAmount,
  lineItemPrice,
  lineItemCompareAtPrice,
} from '~/utils/cart'

const DrawerLineItem = props => {
  const { item, setDrawerOpen } = props
  const { updateCartLines, removeCartLines } = useContext(StoreContext)

  const variant = item?.merchandise

  const variantImage = variant?.image ? (
    <img
      className="absolute inset-0 rounded"
      src={variant.image.url}
      alt={variant?.product?.title ?? ''}
    />
  ) : null

  const addQty = item => {
    updateCartLines([
      {
        id: item.id,
        quantity: item.quantity + 1,
      },
    ])
  }

  const removeQty = item => {
    updateCartLines([
      {
        id: item.id,
        quantity: item.quantity - 1,
      },
    ])
  }

  const handleRemove = () => {
    removeCartLines([item.id])
  }

  const itemQty =
    item?.quantity?.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) ?? 0

  if (variant !== null) {
    return (
      <div className="grid grid-cols-12 items-stretch gap-3 pb-5 mb-5 border-b border-platinum last:mb-0 text-sm">
        {variant?.product?.handle !== 'fabric-swatch' ? (
          <Link
            className="col-span-3"
            onClick={() => setDrawerOpen(false)}
            to={`/products/${variant?.product?.handle}/`}
          >
            <div className="rounded relative w-full h-0 pt-[100%] bg-isabelline">
              {variantImage}
            </div>
          </Link>
        ) : (
          <div className="col-span-3">
            <div className="rounded relative w-full h-0 pt-[100%]">
              {variantImage}
            </div>
          </div>
        )}
        <div className="col-span-9 relative flex flex-col">
          <div className="w-full flex space-x-3 space-between">
            <div className="flex-auto">
              <span className="block font-bold text-rich-black">
                {item?.merchandise?.product?.title}
              </span>

              {item?.variant?.merchandise?.title !== 'Default Title' && (
                <span className="block text-dim-gray">
                  {getVariantTitle(variant?.title)}
                </span>
              )}

              {item.attributes.map((attribute, i) => {
                if (attribute.key === 'Made-to-Order') {
                  return (
                    <span key={i} className="block text-dim-gray">
                      Made-to-Order
                    </span>
                  )
                }

                return (
                  <span key={i} className="block text-dim-gray">
                    {attribute.key}: {attribute.value}
                  </span>
                )
              })}
            </div>

            <div className="flex-auto text-right">
              <button
                className="relative bottom-[1px] p-[7px] -mr-[7px] hover:opacity-60"
                onClick={handleRemove}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                >
                  <path
                    d="M-15737.718,726.231a.708.708,0,0,1-.5-.208l-6.577-6.577a.712.712,0,0,1,0-1.006.711.711,0,0,1,1.006,0l6.577,6.577a.712.712,0,0,1,0,1.006A.711.711,0,0,1-15737.718,726.231Z"
                    transform="translate(15745.006 -718.232)"
                    fill="#0e141a"
                  />
                  <path
                    d="M7.288,8a.708.708,0,0,1-.5-.208L.208,1.214A.711.711,0,0,1,1.214.208L7.791,6.786A.711.711,0,0,1,7.288,8Z"
                    transform="translate(8 0.001) rotate(90)"
                    fill="#0e141a"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="w-full flex space-x-3 space-between mt-auto pt-3">
            <div className="flex items-center rounded bg-[#ECECEC] text-[12px]">
              <button className="p-2" onClick={() => removeQty(item)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="1"
                  viewBox="0 0 6 1"
                >
                  <path
                    d="M-15957.334,821h6"
                    transform="translate(15957.334 -820.5)"
                    fill="none"
                    stroke="#0e141a"
                    strokeWidth="1"
                  />
                </svg>
              </button>

              <span className="block font-medium">{itemQty}</span>

              <button className="p-2" onClick={() => addQty(item)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                >
                  <g transform="translate(-1567.5 -492.5)">
                    <path
                      d="M-15957.334,821h6"
                      transform="translate(17524.834 -325.5)"
                      fill="none"
                      stroke="#0e141a"
                      strokeWidth="1"
                    />
                    <path
                      d="M-15957.334,821h6"
                      transform="translate(2391.5 16449.834) rotate(90)"
                      fill="none"
                      stroke="#0e141a"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <DrawerLineItemPrice item={item} variant={variant} />
          </div>
        </div>
      </div>
    )
  }

  return null
}

const DrawerLineItemPrice = ({ item, variant }) => {
  const hasDiscount = lineItemHasDiscount(item)
  const discountAmount = lineItemDiscountAmount(item)
  const price = lineItemPrice(item, hasDiscount, discountAmount)
  const compareAtPrice = lineItemCompareAtPrice(item, hasDiscount)

  return (
    <div className="flex-auto text-right">
      <span
        className={classNames('inline-block', {
          'mr-2 text-[#327A4F] font-bold':
            item.merchandise.compareAtPrice || hasDiscount,
          'text-rich-black font-medium':
            !item.merchandise.compareAtPrice && !hasDiscount,
        })}
      >
        {formatPrice(price.currencyCode, price.amount)}
      </span>
      {item.merchandise.compareAtPrice || hasDiscount ? (
        <span className="inline-block line-through text-[#9c9e9f]">
          {formatPrice(compareAtPrice.currencyCode, compareAtPrice.amount)}
        </span>
      ) : null}
    </div>
  )
}

export default DrawerLineItem
