import React from 'react'
import MobileNavigation from '~/components/Navigation/MobileMenu/MobileNavigation'
import MobileShopMenu from '~/components/Navigation/MobileMenu/MobileShopMenu'
import MobileGetStartedMenu from '~/components/Navigation/MobileMenu/MobileGetStartedMenu'
import MobileWeddingsEventsMenu from '~/components/Navigation/MobileMenu/MobileWeddingsEventsMenu'
import MobileResourcesMenu from '~/components/Navigation/MobileMenu/MobileResourcesMenu'

const DynamicMobileMenu = ({ menuName, active, setActive, routeChange }) => {
    const components = {
        'Main': () => <MobileNavigation
            active={active}
            isActive={active === 'Main'}
            setActive={setActive}
            routeChange={routeChange}
        />,
        'Shop': () => <MobileShopMenu
            active={active}
            isActive={active === 'Shop'}
            setActive={setActive}
            routeChange={routeChange}
        />,
        'Get Started': () => <MobileGetStartedMenu
            active={active}
            isActive={active === 'Get Started'}
            setActive={setActive}
            routeChange={routeChange}
        />,
        'Weddings & Events': () => <MobileWeddingsEventsMenu
            active={active}
            isActive={active === 'Weddings & Events'}
            setActive={setActive}
            routeChange={routeChange}
        />,
        'Resources': () => <MobileResourcesMenu
            active={active}
            isActive={active === 'Resources'}
            setActive={setActive}
            routeChange={routeChange}
        />,
    };

    const Component = components[menuName];

    return <>{Component ? <Component /> : null}</>;
}

export default DynamicMobileMenu