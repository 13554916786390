import React, { useState } from 'react'
import { navigate } from "gatsby"
import { Formik, Form, Field } from 'formik'
import SearchIcon from "~/images/svg/white-search-icon.svg"

const DesktopSearchBar = () => {
    // const inputRef = useRef(null);
    const [open, setOpen] = useState(false)
    const manageSearch = (values) => {
        navigate(`/search?query=${values.desktopSearchTerms}`)
    }

    // useEffect(() => {
    //     if (open) {
    //         inputRef.current?.focus()
    //     }

    // }, [open, inputRef])


    return (
        <>
            <div className={`${open ? `h-auto w-auto opacity-100 visible` : `h-0 w-0 opacity-0 invisible`}`}>
                <Formik
                    initialValues={{
                        desktopSearchTerms: ''
                    }}
                    className={`${open ? `h-auto w-auto opacity-100` : `h-0 w-0 opacity-0`}`}
                    onSubmit={manageSearch}
                >
                    {({ isSubmitting, values, errors, touched }) => (
                        <Form className="mb-0">
                            <div className={`h-[31px] text-sm flex items-center justify-between rounded-full border border-[#3E3E3E] focus-within:border-white transition-all ${open ? `px-5 pr-4 w-[300px]` : `w-0`}`}>
                                <div className="flex-1">
                                    <Field
                                        id="desktopSearchTerms"
                                        name="desktopSearchTerms"
                                        type="search"
                                        placeholder="Search"
                                        // innerRef={inputRef}
                                        className="w-full bg-transparent text-white placeholder-white focus:outline-none placeholder-opacity-50"
                                    />
                                </div>
                                <div className="ml-4">
                                    <button type="submit" className="text-white focus:outline-none relative top-[2px]" aria-label="Search">
                                        <SearchIcon />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>


            {!open ? <button onClick={() => {
                setOpen(true)
            }} className="p-2 pr-0">
                <SearchIcon />
            </button> : null}
        </>
    )
}

export default DesktopSearchBar