import React, { useContext, useState } from 'react'
import FitFinderContext from '~/context/FitFinderContext'
import useFetchFitData from '~/components/Products/Hooks/useFetchFitData'
import axios from 'redaxios'
import { v4 as uuidv4 } from 'uuid';
import {
    defaultErrorMessages,
    getFieldsByStep,
    getResponseErrorStatus,
    getResponseStep,
    scrollFitPage,
    translateFormikValuesToContext,
    translateFormikValuesForSubmission
} from '~/components/FitFinder/utils'



/**
 * A wrapper for the FitFinderContext that provides additional
 * functionality for the FitFinder quiz.
 *
 * @param {object} children The child components to wrap.
 * @return {JSX.Element} The FitFinderProvider component.
 */
const FitFinderProvider = ({ children }) => {
    const context = useContext(FitFinderContext)
    const [validating, setValidating] = useState(false)

    const {
        fitData,
        fetching: fetchingFitData,
        refetch: refetchFitData,
        clearFitData,
        setFitData,
        setPartialFitData
    } = useFetchFitData()



    /**
     * Get the step number for the review step.
     *
     * @return {Number} The step number for the review step.
     */
    const getReviewStep = () => {
        return getStepLimit() - 1
    }



    /**
     * Get the number of steps for the current fit type.
     *
     * @return {Number} The number of steps for the current fit type.
     */
    const getStepLimit = () => {
        return fitData?.fitType ?
            context.stepCounts[fitData.fitType] :
            context.stepCounts['men']
    }



    /**
     * Go to the first step of the quiz, clearing recommendations and
     * review status.
     *
     * @return {void}
     */
    const goToBeginning = () => {
        setPartialFitData({
            currentStep: 1,
            reviewing: false,
            completedQuiz: 0,
            recommendations: {
                'men': {
                    jacket: [],
                    pant: []
                },
                'women': {
                    jacket: [],
                    pant: []
                }
            }
        })

        scrollFitPage()
    }



    /**
     * Go to the next step of the quiz, validating the form and
     * setting the new current step.
     *
     * @return {void}
     */
    const goToNextStep = (currentStep, setTouched, values, validateForm) => {

        const max = getStepLimit()

        validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                const newStep = currentStep + 1

                setPartialFitData({
                    ...translateFormikValuesToContext(values),
                    currentStep: newStep > max ? max : newStep
                })
                setValidating(false)
            } else {
                const fieldsToTouch = getFieldsByStep(currentStep).reduce(
                    (prev, cur) => ({ ...prev, [cur]: true }), {}
                )
                setTouched(fieldsToTouch)
                setValidating(true)
            }

            scrollFitPage()
        })
    }



    /**
     * Step back to the previous step.
     *
     * @return {void}
     */
    const goToPreviousStep = (currentStep) => {
        const newStep = currentStep - 1

        setPartialFitData({
            currentStep: newStep < 0 ? 0 : newStep
        })

        scrollFitPage()
    }



    /**
     * Go to the review step.
     *
     * @return {void}
     */
    const goToReviewStep = (currentStep, setTouched, values, validateForm) => {
        validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                const reviewStep = getReviewStep()

                setPartialFitData({
                    ...translateFormikValuesToContext(values),
                    currentStep: reviewStep,
                    reviewing: false
                })
                setValidating(false)
            } else {
                const fieldsToTouch = getFieldsByStep(currentStep).reduce(
                    (prev, cur) => ({ ...prev, [cur]: true }), {}
                )
                setTouched(fieldsToTouch)
                setValidating(true)
            }

            scrollFitPage()
        })
    }



    /**
     * Review a specific step of the quiz.
     *
     * @return {void}
     */
    const reviewStep = (step) => {
        setPartialFitData({
            currentStep: step,
            reviewing: true
        })
    }



    /**
     * Start the quiz over, clearing all fit data.
     *
     * @return {void}
     */
    const startOver = () => {
        clearFitData()
        scrollFitPage()
    }



    /**
     * Start the quiz, setting the anonId and current step.
     *
     * @return {void}
     */
    const startQuiz = () => {
        const uuid = uuidv4();

        setPartialFitData({
            anonId: uuid,
            begunQuiz: 1,
            currentStep: 1,
            recommendations: {}
        })

        scrollFitPage()
    }



    /**
     * Submit the Fit Finder quiz to the API.
     *
     * @return {void}
     */
    const submit = (values, actions) => {
        const valuesForSubmission = translateFormikValuesForSubmission(values)
        let enhancing = true
        let loading = true

        let recommendations = {
            men: {
                jacket: [],
                pant: []
            },
            women: {
                jacket: [],
                pant: []
            }
        }

        let newData = {}

        axios.post(`${process.env.GATSBY_ADMIN_ENDPOINT}/api/fit`, valuesForSubmission)
            .then((response) => {
                // console.log('[FF] Response', response)
                let data = response.data

                if (data && data.recommendations) {
                    recommendations[values.fitType] = data.recommendations
                }

                newData = {
                    completedQuiz: 1,
                    recommendations: recommendations,
                    errorStatus: data?.error_messages?.length > 0 || false,
                    errorMessages: data?.error_messages || [],
                    outlierStatus: data?.outlier_status || false,
                    outlierMessages: data?.outlier_messages || [],
                    message: data?.message || '',
                    email: values.email
                }

                if (newData?.errorStatus || newData?.outlierStatus) {
                    newData.currentStep = 6
                    newData.completedQuiz = 0
                }

                loading = false
            }).catch((response) => {
                // console.error('[FF] Error', response)

                try {
                    let data = response.data

                    newData = {
                        completedQuiz: 0,
                        currentStep: getResponseStep(data),
                        recommendations: recommendations,
                        errorStatus: getResponseErrorStatus(data),
                        errorMessages: data?.error_messages || defaultErrorMessages,
                        outlierStatus: data?.outlier_status || false,
                        outlierMessages: data?.outlier_messages || [],
                        message: data?.message || '',
                        email: values.email
                    }
                } catch (e) {
                    console.error('Error parsing response', e)
                }

                loading = false
            })

        // Only enhance loader for 3 seconds
        setTimeout(() => enhancing = false, 7000)

        // Only stop loading after fetching data
        const intervalFunction = () => {
            if (!loading && !enhancing) {
                actions.setSubmitting(false)
                setPartialFitData(newData)
                clearInterval(interval)
            }
        }

        const interval = setInterval(intervalFunction, 500)
    }



    return (
        <FitFinderContext.Provider value={{
            ...context,        // Pull in default values
            ...fitData,        // Pull in fit data from hook
            fetchingFitData,
            goToBeginning,
            goToNextStep,
            goToPreviousStep,
            goToReviewStep,
            refetchFitData,
            clearFitData,
            setFitData,
            setPartialFitData,
            getStepLimit,
            getReviewStep,
            startQuiz,
            startOver,
            submit,
            reviewStep,

            // validation
            validating,
            setValidating
        }}>
            {children}
        </FitFinderContext.Provider>
    )
}

export default FitFinderProvider