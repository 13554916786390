import React from 'react'

const StoreContext = React.createContext({
    store: {
        fetching: true,
        adding: false,
        updating: false,
        cart: {},
        products: [],
        shop: {},
        addToCart: () => { },
    },
    fitRecommendations: {
        men: {
            jacket: null,
            pant: null
        },
        women: {
            jacket: null,
            pant: null
        }
    }
})

export default StoreContext
