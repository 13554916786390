import React, { useState } from "react"
import { Overlay, OverlayTransition } from './base'


export const DrawerModal = ({ isOpen, setIsOpen, children }) => {
    const [overlayVisible, setOverlayVisible] = useState(false)

    React.useEffect(() => {
        let gorgias = document.querySelector('#chat-button')
        isOpen && gorgias ? gorgias?.classList.add('hidden') : gorgias?.classList.remove('hidden')

        let timer = false
        if (isOpen) {
            setOverlayVisible(true)
            document.body.style.overflow = 'clip';
            document.documentElement.style.overflow = 'clip';
            document.body.style.height = 'auto'
            document.documentElement.style.height = 'auto'
        } else {
            timer = setTimeout(() => setOverlayVisible(false), 400);
        }

        return () => {
            if (timer) clearTimeout(timer)
            document.body.style.overflow = '';
            document.documentElement.style.overflow = ''
        }
    }, [isOpen])

    // add screenreader hidden
    // add scoped focus into this modal areaaaaa
    return (
        <div className={`transition-opacity duration-200 fixed inset-0 flex z-100 ${overlayVisible ? `opacity-100` : `pointer-events-none opacity-0`}`}>
            <div className="absolute inset-0 overflow-hidden">
                <OverlayTransition open={overlayVisible} setOpen={setIsOpen}>
                    <Overlay className="opacity-10" />
                </OverlayTransition>

                <div aria-modal={isOpen} role="dialog" aria-labelledby="drawerLabel" className={`transition-all duration-500 ease-in-out h-full flex flex-col overflow-y-scroll py-3.5 ml-auto relative w-[448px] max-w-full bg-white shadow-xl ${isOpen ? `mr-0` : `-mr-[448px]`}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}