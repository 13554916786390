import React from 'react'
import { Link } from 'gatsby'
import NavRightArrow from '~/images/svg/arrow-right-nav.svg'

const MobileNavigation = ({ active, isActive, setActive, routeChange }) => {
    if (isActive) {
        return (
            <ul className="text-base">
                <li>
                    <button className="flex justify-between items-center py-3 w-full text-left" onClick={() => setActive('Shop')}>
                        Shop
                        <NavRightArrow />
                    </button>
                </li>
                <li>
                    <button className="flex justify-between items-center py-3 w-full text-left" onClick={() => setActive('Get Started')}>
                        Get Started
                        <NavRightArrow />
                    </button>
                </li>
                <li>
                    <button className="flex justify-between items-center py-3 w-full text-left" onClick={() => setActive('Weddings & Events')}>
                        Weddings & Events
                        <NavRightArrow />
                    </button>
                </li>
                <li>
                    <Link className="flex justify-between items-center py-3 w-full text-left" to={`/fit-guide/`} onClick={routeChange}>
                        Fit Guide
                        <NavRightArrow />
                    </Link>
                </li>
                <li>
                    <button className="flex justify-between items-center py-3 w-full text-left" onClick={() => setActive('Resources')}>
                        Resources & Help
                        <NavRightArrow />
                    </button>
                </li>
            </ul>
        )
    }

    return null
}

export default MobileNavigation