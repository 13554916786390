import cartFragment from '../fragments/cart'

/**
 * Create Cart
 * 
 * @returns {string}
 */
export const createCartMutation = `
  mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`

/**
 * Add Cart Lines
 * 
 * @returns {string}
 */
export const addCartLinesMutation = `
  mutation addCartLines($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`

/**
 * Update Cart Lines
 * 
 * @returns {string}
 */
export const updateCartLinesMutation = `
  mutation updateCartLines($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`

/**
 * Remove Cart Lines
 * 
 * @returns {string}
 */
export const removeCartLinesMutation = `
  mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`

/**
 * Set Cart Attributes
 * 
 * @returns {string}
 */
export const setCartAttributesMutation = `
  mutation setCartAttributes($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`