import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { getDynamicMegaMenuClasses } from '~/utils/navigation'

import {
  MegaMenuPanel,
  MegaListGrid,
  MegaListGridColumn,
  MegaList,
  MegaListItem,
  MegaLink,
  MegaExternalLink,
  MegaListTitle,
} from '~/styles/navigation'

const WeddingsEventsMenu = ({ isActive }) => {
  const title = 'Weddings&Events'
  const menuRef = useRef(null)

  return (
    <MegaMenuPanel
      id={`${title}-panel`}
      aria-labelledby={`${title}-button`}
      className={`${getDynamicMegaMenuClasses(isActive)} py-10`}
    >
      <MegaListGrid className="max-w-[1019px] grid-cols-5">
        <MegaListGridColumn className="col-span-2">
          <div className="grid grid-cols-2 gap-10">
            <MegaListGridColumn>
              <MegaList>
                <MegaListItem className="mb-0 font-bold">
                  <MegaListTitle>Weddings & Events</MegaListTitle>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/weddings/" ref={menuRef}>
                    Suit a Group
                  </MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/swatches/">Fabric Swatches</MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/store-locations/">
                    Book an Appointment
                  </MegaLink>
                </MegaListItem>
              </MegaList>
            </MegaListGridColumn>

            <MegaListGridColumn>
              <MegaList>
                <MegaListItem className="mb-0 font-bold">
                  <MegaListTitle>Inspiration</MegaListTitle>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/blogs/real-weddings/">
                    Wedding Inspiration
                  </MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/blogs/news/">The Style Blog</MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaExternalLink
                    href="https://instagram.com/suitshopofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </MegaExternalLink>
                </MegaListItem>
              </MegaList>
            </MegaListGridColumn>
          </div>
        </MegaListGridColumn>

        <MegaListGridColumn className="col-span-3">
          <div className="grid grid-cols-2 gap-4">
            <MegaListGridColumn>
              <Link className="block" to={`/swatches/`}>
                <StaticImage
                  src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto:low,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/events_swatches2.jpg"
                  width={300}
                  quality={90}
                  className="object-cover w-full bg-isabelline"
                  alt="Fabric Swatches"
                />
                <span className="inline-block pt-2 hover:underline">
                  Fabric Swatches
                </span>
              </Link>
            </MegaListGridColumn>

            <MegaListGridColumn>
              <Link className="block" to={`/blogs/real-weddings/`}>
                <StaticImage
                  src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto:low,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/events_weddings2.jpg"
                  width={300}
                  quality={90}
                  className="object-cover w-full bg-isabelline"
                  alt="Real Weddings"
                />

                <span className="inline-block pt-2 hover:underline">
                  Real Weddings
                </span>
              </Link>
            </MegaListGridColumn>
          </div>
        </MegaListGridColumn>
      </MegaListGrid>
    </MegaMenuPanel>
  )
}

export default WeddingsEventsMenu
