import React from 'react';
import HamburgerIcon from '~/images/svg/hamburger.svg'

const Hamburger = ({ onClick }) => {
    return (
        <button aria-label="Open menu" className="menu block xl:hidden xl:invisible p-2 mt-[3px]" onClick={onClick}>
            <span className="sr-only">Open menu</span>
            <HamburgerIcon />
        </button>
    )
}

export default Hamburger