export const handleToggle = (callback, item) => {
    callback(item)
}

export const handleKey = (e, callback, title, active) => {
    const isActive = active === title

    if (e.keyCode === 32 || e.keyCode === 13) {
        callback(!isActive ? title : false)
    }

    if (e.keyCode === 27) {
        callback(false)
    }
}

export const getDynamicMegaMenuClasses = (isActive) => {
    return isActive ?
        'transition-all ease-in duration-150 opacity-100' :
        'transition-all ease-out duration-200 opacity-0 invisible'
}