import React from 'react'
import { Link } from 'gatsby'

import CalendarIcon from '~/images/svg/calendar-white.svg'
import ReturnsIcon from '~/images/svg/returns-white.svg'
import HelpIcon from '~/images/svg/help-white-mobile.svg'
import PhoneIcon from '~/images/svg/phone-white.svg'

const SecondaryMobileNavigation = ({ routeChange }) => {
    return (
        <ul className="text-base">
            <li>
                <Link className="flex items-center py-2.5 w-full text-left" to={`/store-locations/`} onClick={routeChange}>
                    <CalendarIcon className="mr-3.5 w-[20px] h-[20px]" />
                    Book an Appointment
                </Link>
            </li>
            <li>
                <Link className="flex items-center py-2.5 w-full text-left" to={`/returns/`} onClick={routeChange}>
                    <ReturnsIcon className="mr-3.5 w-[20px] h-[20px]" />
                    Returns & Exchanges
                </Link>
            </li>
            <li>
                <a className="flex items-center py-2.5 w-full text-left" href="https://suitshop.gorgias.help/" target="_blank" rel="noopener noreferrer">
                    <HelpIcon className="mr-3.5 w-[20px] h-[20px]" />
                    Help
                </a>
            </li>
            <li>
                <Link className="flex items-center py-2.5 w-full text-left" to={`/contact/`} onClick={routeChange}>
                    <PhoneIcon className="mr-3.5 w-[20px] h-[20px]" />
                    Contact Us
                </Link>
            </li>
        </ul>
    )
}

export default SecondaryMobileNavigation