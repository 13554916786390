import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'

import {
    handleToggle
} from '~/utils/navigation'

const SimpleLink = tw(Link)`relative cursor-pointer inline-block py-4 xl:px-6 2xl:px-10`
const LinkUnderline = tw.div`relative top-[3px] w-full h-[1px] bg-rich-black`

const NavLink = ({ title, to, active, callback }) => {
    const prettyTitle = title
    title = title.replace(/\s/g, '');
    const isActive = active === title

    return (
        <SimpleLink
            to={to}
            id={`${title}-link`}
            onMouseOver={() => handleToggle(callback, title)}
            onMouseLeave={() => handleToggle(callback, false)}
            tabIndex="0"
            className="group"
        >
            <span>{prettyTitle}</span>
            <LinkUnderline
                className={`${isActive ? `opacity-100` : `opacity-0`}`}
            />
        </SimpleLink>
    )
}

export default NavLink