import React from 'react'
import { navigate } from "gatsby"
import { Formik, Form, Field } from 'formik'

const MobileSearchBar = ({ routeChange }) => {
    const manageSearch = (values, actions) => {
        routeChange()
        navigate(`/search?query=${values.mobileSearchTerms}`)
    }

    return (
        <Formik
            initialValues={{
                mobileSearchTerms: ''
            }}
            onSubmit={manageSearch}
        >
            {({ isSubmitting, values, errors, touched }) => (
                <Form className="mb-0">
                    <div className="h-[38px] flex items-center justify-between rounded-full border border-[#3E3E3E] focus-within:border-white px-4">
                        <div className="flex-1">
                            <Field
                                autoComplete="off"
                                id="mobileSearchTerms"
                                name="mobileSearchTerms"
                                type="search"
                                placeholder="Search"
                                className="w-full bg-transparent text-white placeholder-white focus:outline-none placeholder-opacity-60"
                            />
                        </div>
                        <div className="ml-4">
                            <button type="submit" className="text-white focus:outline-none relative top-[2px]" aria-label="Search">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.625" height="13.625" viewBox="0 0 13.625 13.625">
                                    <path id="Nav_search_icon_mobile" d="M14.918,14.918l-3.973-3.973M12.27,7.635A4.635,4.635,0,1,1,7.635,3,4.635,4.635,0,0,1,12.27,7.635Z" transform="translate(-2 -2)" fill="none" stroke="#fff" strokeWidth="2" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default MobileSearchBar