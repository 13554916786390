/** @jsx jsx */
import { css } from '@emotion/react'

export const breakpoints = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    ws: '1536px'
}


export const mq = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
        accumulator[label] = (...args) => css`
            @media (min-width: ${breakpoints[label]}) {
                ${css(...args)};
            }
        `;
        return accumulator;
    },
    {}
);