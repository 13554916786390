import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MobileMenuTitle from '~/components/Navigation/MobileMenu/MobileMenuTitle'

import {
  MobileList,
  MobileListItem,
  MobileLink,
  MobileListTitle,
  MobileDivider,
} from '~/styles/navigation'

const MobileGetStartedMenu = ({ active, isActive, setActive, routeChange }) => {
  if (isActive) {
    return (
      <>
        <MobileMenuTitle title={active} setActive={setActive} />

        <MobileList>
          <MobileListItem className="mb-1 font-medium text-base">
            <MobileListTitle>Start Here</MobileListTitle>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/how-it-works/" onClick={routeChange}>
              How It Works
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/store-locations/" onClick={routeChange}>
              Book an Appointment
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/swatches/" onClick={routeChange}>
              Fabric Swatches
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/weddings/" onClick={routeChange}>
              Register a Group
            </MobileLink>
          </MobileListItem>
        </MobileList>

        <MobileDivider />

        <div className="pt-3 pb-6 space-y-6">
          <Link className="block" to={`/fit-guide/`} onClick={routeChange}>
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/get_started_size_fit2.jpg"
              width={358}
              height={233}
              quality={100}
              backgroundColor={`#171C21`}
              className="object-cover bg-[#171C21] w-full h-[218px] md:h-[233px]"
              alt="Size & Fit"
            />
            <span className="inline-block pt-2 underline">Size & Fit</span>
          </Link>

          <a
            className="block"
            href="https://suitshop.gorgias.help/en-US/articles/orders-and-shipping-4135"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/get_started_shipping2.jpg"
              width={358}
              height={233}
              quality={100}
              backgroundColor={`#171C21`}
              className="object-cover bg-[#171C21] w-full h-[218px] md:h-[233px]"
              alt="Order & Shipping FAQs"
            />

            <span className="inline-block pt-2 underline">
              Order & Shipping FAQs
            </span>
          </a>
        </div>
      </>
    )
  }

  return null
}

export default MobileGetStartedMenu
