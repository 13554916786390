import React from 'react';
import * as localForage from 'localforage';
import './src/styles/global.css';
import { trackPageView, trackAffiliate } from "~/utils/tracking"

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from './src/utils/linkResolver';


export const wrapRootElement = ({ element }) => {
    return (
        <PrismicPreviewProvider
            repositoryConfigs={[
                {
                    repositoryName: 'suitshop',
                    linkResolver: linkResolver,
                    componentResolver: {
                        'blog_post': React.lazy(() => import('./src/templates/Blog/StyleBlog/post')),
                    }
                }
            ]}
        >
            {element}
        </PrismicPreviewProvider>
    )
}


export const onClientEntry = async () => {

    try {
        if (typeof IntersectionObserver === `undefined`) {
            await import(`intersection-observer`);
        }
    } catch (error) {
        console.log(error)
    }

    try {
        trackAffiliate()
    } catch (error) {
        console.log(error)
    }

}

export const onRouteUpdate = ({ location, prevLocation }) => {
    try {
        trackPageView()
    } catch (error) {
        console.log(error)
    }

    try {
        if (location && location?.pathname) {
            if (
                !location.pathname.includes('women') &&
                (
                    location.pathname.includes('products') ||
                    location.pathname.includes('collections')
                )
            ) {
                localForage.setItem('fitfinder_referrer', location.pathname)
            }
        } else {
            console.error('No location or pathname')
        }
    } catch (error) {
        console.log(error)
    }
}