import React, { useContext } from 'react';
import CartIcon from '~/images/svg/cart.svg'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'

const useQuantity = () => {
    const { store: { cart } } = useContext(StoreContext)
    const items = cart?.lines?.edges ?? []
    const total = items.reduce((acc, item) => acc + item?.node?.quantity, 0)
    return total
}

const Cart = ({ onClick }) => {
    const quantity = useQuantity()

    return (
        <Link to="/cart/" className={`menu block ${quantity > 0 ? `p-2 !mr-1 md:mr-2 xl:!mr-0` : `p-2`} relative`} onClick={onClick}>
            <span className="sr-only">My Cart</span>
            <CartIcon className="inline" />

            {quantity > 0 ? <span className="absolute top-[3px] -right-[1px] p-2 rounded-full w-[10px] h-[10px] text-white text-[9px] bg-rich-black flex items-center justify-center font-medium">{quantity}</span> : null}
        </Link>
    )
}

export default Cart