import { getIdFromGraph, getCheckoutIdFromBase64, getQueryParameters, getPlainImageUrl } from "~/utils/helpers"
import * as localForage from "localforage";
import axios from 'redaxios';
const isBrowser = typeof window !== 'undefined'

const createArrayGroups = (arr, numGroups) => {
    const perGroup = Math.ceil(arr.length / numGroups);
    return new Array(numGroups)
        .fill('')
        .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
}

export const applyLinker = (checkoutUrl) => {
    let destinationLink = checkoutUrl;

    try {
        destinationLink = destinationLink.replace('thegroomsmansuit.myshopify.com', 'store.suitshop.com');

        if (isBrowser) {
            if (typeof window.ga === `function` && window.ga !== null) {
                let tracker = window.ga.getAll()[0];
                let linker = new window.gaplugins.Linker(tracker);
                destinationLink = linker.decorate(destinationLink);
            }
        }
    } catch (error) {
        console.error(error);
    }

    return destinationLink;
}

// DONE
export const trackProductView = async (products) => {
    try {
        if (isBrowser) {
            let items = []
            let gaItems = [];
            let totalValue = 0

            products.forEach((product, index) => {
                let productId = getIdFromGraph(product.shopifyId);
                let productPrice = parseFloat(product.priceRange.minVariantPrice.amount);
                let productImage = typeof product.images !== "undefined" && product.images[0] ?
                    product.images[0].originalSrc : null;
                totalValue += productPrice


                let productData = {
                    'name': product.title,
                    'productId': productId,
                    'productVariantId': product.variants ? getIdFromGraph(product.variants[0].shopifyId) : null,
                    'productImage': getPlainImageUrl(productImage),
                    'price': {
                        value: productPrice,
                        currency: 'USD',
                    },
                    'quantity': 1,
                    'category': typeof product.productType !== 'undefined' ? product.productType : 'All'
                }

                items.push({
                    ...productData,
                    sku: null,
                    brand: 'SuitShop',
                    variantName: null,
                    trialItem: false
                })

                gaItems.push({
                    item_name: product.title,
                    item_id: productId,
                    price: productPrice,
                    item_brand: 'SuitShop'
                })
            })

            trackAttentive(items, 'productView')
            trackGoogleProductView(products, gaItems, totalValue)
            trackFacebook('ViewContent', items, totalValue)
            trackTiktok('ViewContent', items, totalValue)
        }
    } catch (error) {
        console.log(error)
    }
}

// DONE
export const trackProductListingView = async (collection) => {
    try {
        setTimeout(() => {
            if (isBrowser && typeof window.gtag === `function`) {
                const items = collection.products.map((product, index) => ({
                    item_id: getIdFromGraph(product.shopifyId),
                    item_name: product.title,
                    price: parseFloat(product.priceRange.minVariantPrice.amount),
                    item_brand: 'SuitShop',
                    item_category: 'Apparel',
                    index: index + 1
                }))

                const groupedItems = createArrayGroups(items, 3)

                window.gtag('event', 'Viewed Product Listing', {
                    'event_category': collection.title
                })

                groupedItems.forEach(group => {
                    window.gtag("event", "view_item_list", {
                        item_list_name: collection.title,
                        items: group
                    });
                });
            }
        }, 1500);
    } catch (error) {
        console.log(error);
    }
}

// DONE
export const trackPageView = async () => {
    try {
        setTimeout(() => {
            if (isBrowser && typeof window.fbq === `function` && window.fbq !== null) {
                console.log('[FBQ] Track page view')
                window.fbq('track', 'PageView');
            }

            if (isBrowser && typeof window.gtag === `function`) {
                window.gtag('event', 'page_view', {
                    page_location: window.location.href,
                    page_path: window.location.pathname
                })
            }

            if (isBrowser && typeof window.letterpress === `function`) {
                window.letterpress('trackPageView');
            }

            if (isBrowser && typeof window.amzn === `function`) {
                window.amzn('trackEvent', 'PageView');
            }
        }, 300);
    } catch (error) {
        console.log(error);
    }
}

// DONE
export const trackAddToCart = async (product, variants, checkoutId) => {
    try {
        if (isBrowser) {
            const translatedCheckoutId = getCheckoutIdFromBase64(checkoutId)
            let totalAddValue = 0
            let cartItems = []
            let gaVariants = []
            let pinterestVariants = []

            variants.forEach(variant => {
                let variantImage = variant?.image?.originalSrc ?? null
                let productImage = variant?.product?.featuredImage?.originalSrc ?? null
                let variantPrice = typeof variant?.price?.amount !== 'undefined' ? parseFloat(variant.price.amount) : parseFloat(variant.price)
                totalAddValue += variantPrice

                let productId = getIdFromGraph(product.shopifyId);
                let variantId = getIdFromGraph(variant.shopifyId);

                let variantData = {
                    'name': product.title,
                    'productId': productId,
                    'productVariantId': variantId,
                    'productImage': variantImage !== null ?
                        getPlainImageUrl(variantImage) : getPlainImageUrl(productImage),
                    'price': {
                        value: variantPrice,
                        currency: 'USD',
                    },
                    'quantity': 1,
                    'category': typeof product.productType !== 'undefined' ? product.productType : 'All'
                }

                cartItems.push({
                    ...variantData,
                    sku: variant.sku,
                    brand: 'SuitShop',
                    variantName: variant.title,
                    trialItem:  false
                })

                gaVariants.push({
                    item_id: variantId,
                    item_name: product.title,
                    item_variant: variant.title !== 'Default Title' ? variant.title : null,
                    price: parseFloat(variant.price),
                    item_brand: 'SuitShop',
                })

                pinterestVariants.push({
                    'product_name': product.title,
                    'product_id': productId,
                    'product_variant': variant.title,
                    'product_variant_id': variantId,
                    'product_price': parseFloat(variant.price),
                    'product_quantity': 1
                })

                if (typeof window.TriplePixel === `function` && window.TriplePixel !== null) {
                    window.TriplePixel('AddToCart', { item: productId, q: 1, token: translatedCheckoutId })
                }
            })

            trackGoogleAddToCart(product, gaVariants, totalAddValue)
            trackFacebook('AddToCart', cartItems, totalAddValue, translatedCheckoutId)
            trackTiktok('AddToCart', cartItems, totalAddValue)
            trackAttentive(cartItems, 'addToCart', translatedCheckoutId)
            trackSuitShop('add-to-cart', gaVariants, translatedCheckoutId, totalAddValue)
            trackPinterest('addtocart', pinterestVariants, totalAddValue)

            if (typeof window.amzn === `function`) {
                window.amzn('trackEvent', 'AddToCart');
            }
        }
    } catch (error) {
        console.log(error);
    }
}


// DONE
export const trackGoogleProductView = async (products, items, value) => {
    try {
        setTimeout(() => {
            if (isBrowser && typeof window.gtag === `function`) {
                const category = typeof products[0].productType !== 'undefined' ?
                    products[0].productType : 'All';

                window.gtag('event', 'Viewed Product', {
                    'event_category': category
                })

                window.gtag('event', 'view_item', {
                    currency: 'USD',
                    value: value,
                    items: items
                })
            }
        }, 1500)
    } catch (error) {
        console.log(error)
    }
}


// DONE
export const trackGoogleAddToCart = async (product, items, totalAddValue) => {
    try {
        setTimeout(() => {
            if (isBrowser && typeof window.gtag === `function`) {
                const category = typeof product.productType !== 'undefined' ?
                    product.productType : 'All';

                window.gtag('event', 'Added Product', {
                    'event_category': category
                })

                window.gtag("event", "add_to_cart", {
                    currency: 'USD',
                    value: totalAddValue,
                    items: items
                })

                // Google Shopping App Add To Cart
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-924022112/d6JHCLzPgqoBEODqzbgD',
                    'value': totalAddValue
                });
            }
        }, 1500)
    } catch (error) {
        console.log(error)
    }
}

// DONE
export const trackSuitShop = async (type, variants, checkoutId, totalAddValue) => {

    try {
        if (isBrowser) {
            axios({
                method: "POST",
                url: `${process.env.GATSBY_ADMIN_ENDPOINT}/api/tracking/${type}`,
                data: {
                    'event_id': checkoutId,
                    'content_name': variants?.[0]?.item_name,
                    'content_ids': variants.map(v => v.item_id),
                    'value': totalAddValue,
                    'currency': 'USD',
                    'client_user_agent': window.navigator.userAgent,
                    'event_source_url': window.location.href
                }
            }).catch(error => {
                console.log('error', error);
            });
        }
    } catch (error) {
        console.log('yes', error)
    }
}

// DONE
export const trackPinterest = async (type, items, totalAddValue) => {
    if (typeof window.pintrk === `function` && window.pintrk !== null) {
        window.pintrk('track', type, {
            'value': totalAddValue,
            'currency': 'USD',
            'order_quantity': items.length,
            'line_items': items
        })
    }
}

// DONE
export const trackFacebook = async (type, items, totalValue = 0, checkoutId = null) => {
    if (isBrowser && typeof window.fbq === `function` && window.fbq !== null) {
        window.fbq('track', type, {
            content_name: items?.[0]?.name,
            content_ids: items.map(i => i.productId),
            content_type: 'product',
            value: totalValue,
            currency: 'USD'
        }, (type === 'AddToCart' && checkoutId !== null) ? { eventID: checkoutId } : null)
    }
}

export const trackTiktok = async (type, items = [], totalValue = 0) => {
    try {
        if (isBrowser && typeof window.ttq === `object`) {
            window.ttq.track(type, {
                contents: items.map(i => ({
                    content_id: i.productId,
                    content_name: i.name,
                    content_type: 'product',
                    price: i.price.value,
                    quantity: i.quantity
                })),
                value: totalValue,
                currency: 'USD'
            })
        }
    } catch (error) {
        //
    }
}

// DONE
export const trackAttentive = async (items, type, cartId = null) => {
    if (isBrowser && window.attentive !== null && typeof window.attentive !== "undefined" && typeof window.attentive.analytics !== "undefined" && items.length > 0) {

        await localForage.getItem('customer').then(customer => {
            let data = { items }
            if (customer !== null && customer?.phone) data.user = { phone: customer.phone }
            if (cartId) data.cart = { cartId }

            if (type === 'productView') window.attentive.analytics.productView(data)
            if (type === 'addToCart') window.attentive.analytics.addToCart(data)
        })
    }
}


// DONE
export const trackAffiliate = async () => {

    try {
        if (isBrowser) {
            const location = window.location
            const query = location.search.substring(1);
            const params = getQueryParameters(query);

            if (typeof params['aff_id'] !== 'undefined' && params['aff_id'] !== null) {
                axios.get(
                    `${process.env.GATSBY_ADMIN_ENDPOINT}/api/affiliates/fetch/${params['aff_id']}`
                ).then(function (response) {
                    localForage.setItem('tgs_affiliate', response.data);
                }).catch(function () {
                    // no affiliate
                })
            }
        }
    } catch (error) {
        console.log(error);
    }
}

// DONE
export const trackGroupConversion = async (group) => {
    try {
        if (isBrowser) {
            if (typeof window.fbq === `function` && window.fbq !== null) {
                window.fbq('init', '398641100491036', {
                    em: group?.email ?? null
                })
                window.fbq('trackCustom', 'GroupRegistration', {
                    name: group?.name ?? null
                })
            }

            if (typeof window.ttq === `object`) {
                window.ttq.identify({
                    email: group?.email ?? null
                })
                window.ttq.track('CompleteRegistration', {
                    description: 'Group Registration'
                })
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const trackBooking = async () => {
    try {
        if (isBrowser) {
            if (typeof window.fbq === `function` && window.fbq !== null) {
                window.fbq('trackCustom', 'InitiateBookAppointment')
            }
        }
    } catch (error) {
        console.log(error);
    }
}