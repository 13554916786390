import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getDynamicMegaMenuClasses } from '~/utils/navigation'

import {
  MegaMenuPanel,
  MegaListGrid,
  MegaListGridColumn,
  MegaList,
  MegaListItem,
  MegaLink,
  MegaExternalLink,
  MegaListTitle,
} from '~/styles/navigation'

const ResourcesMenu = ({ isActive }) => {
  const title = 'Resources'
  const menuRef = useRef(null)

  return (
    <MegaMenuPanel
      id={`${title}-panel`}
      aria-labelledby={`${title}-button`}
      className={`${getDynamicMegaMenuClasses(isActive)} py-10`}
    >
      <MegaListGrid className="max-w-[775px] grid-cols-12 gap-8">
        <MegaListGridColumn className="col-span-7">
          <div className="grid grid-cols-2 gap-8">
            <MegaListGridColumn>
              <MegaList>
                <MegaListItem className="mb-0 font-bold">
                  <MegaListTitle>Start Here</MegaListTitle>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/contact/">Contact Us</MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/returns/" ref={menuRef}>
                    Returns & Exchanges
                  </MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/how-to/">How-To Page</MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/corporate-services/">Corporate Services</MegaLink>
                </MegaListItem>
                <MegaListItem>
                  <MegaLink to="/about/">About Us</MegaLink>
                </MegaListItem>
              </MegaList>
            </MegaListGridColumn>

            <MegaListGridColumn>
              <MegaList>
                <MegaListItem className="mb-0 font-bold">
                  <MegaListTitle>Contact</MegaListTitle>
                </MegaListItem>
                <MegaListItem>
                  <MegaExternalLink
                    className="block underline pb-1.5"
                    href="tel:17733036006"
                  >
                    +1 (773) 303-6006
                  </MegaExternalLink>
                  <span className="block">9am-7pm EST (Mon-Fri).</span>
                </MegaListItem>
                <MegaListItem className="pt-6">
                  <MegaExternalLink
                    className="block underline pb-1.5"
                    href="mailto:info@suitshop.com"
                  >
                    info@suitshop.com
                  </MegaExternalLink>
                  <span className="block">
                    Our team typically answers within 24 hours.
                  </span>
                </MegaListItem>
              </MegaList>
            </MegaListGridColumn>
          </div>
        </MegaListGridColumn>

        <MegaListGridColumn className="col-span-5">
          <a
            className="block"
            href="https://suitshop.gorgias.help/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/resources_help2.jpg"
              width={300}
              height={200}
              quality={100}
              className="object-cover w-full bg-isabelline"
              alt="Help Center"
            />
            <span className="inline-block pt-2 hover:underline">
              Help Center
            </span>
          </a>
        </MegaListGridColumn>
      </MegaListGrid>
    </MegaMenuPanel>
  )
}

export default ResourcesMenu
