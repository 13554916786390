import { useState, useCallback, useEffect } from 'react'
import * as localForage from "localforage";
import { defaultData } from '~/components/FitFinder/utils'



/**
 * A hook for fetching and setting fit data.
 *
 * @return {Object} The fit data and functions for fetching and setting it.
 */
const useFetchFitData = () => {

    const [fetching, setFetching] = useState(true)
    const [fitData, setFitData] = useState(defaultData)



    /**
     * Clear fit data from local storage.
     *
     * @return {void}
     */
    const clearFitData = useCallback(async () => {
        localForage.removeItem('fitfinder_data').then(() => {
            setFitData({ ...defaultData })
        })
    }, [])



    /**
     * Fetch fit data from local storage.
     *
     * @return {void}
     */
    const fetchFitData = useCallback(async () => {
        try {
            setFetching(true)

            const fitFinderData = localForage.getItem('fitfinder_data')
            const fitFinderReferrer = localForage.getItem('fitfinder_referrer')

            Promise.all([fitFinderData, fitFinderReferrer]).then(([data, referrer]) => {

                if (data) {
                    setFitData(prevData => ({
                        ...prevData,
                        ...data,
                        entryPoint: referrer ?? data.entryPoint
                    }))
                } else {
                    setFitData({ ...defaultData })
                }

                setFetching(false)
            })
        } catch (error) {
            console.log('Could not fetch fit data', error)
            setFetching(false)
        }
    }, [])



    /**
     * Set the given fit data in state.
     *
     * @param {Object} data The data to set.
     * @return {void}
     */
    const setPartialFitData = useCallback(async (data) => {
        setFitData(prevData => {
            const newData = {
                ...prevData,
                ...data,
                bodyData: {
                    ...prevData.bodyData,
                    ...data?.bodyData
                },
                recommendations: {
                    ...prevData.recommendations,
                    ...data?.recommendations
                },
                // Currently, these two are being overridden every time we update state.
                errorMessages: data?.errorMessages || [],
                outlierMessages: data?.outlierMessages || []
            }

            updateLocalStorage(newData)

            return newData
        })
    }, [])



    /**
     * Update local storage when fit data changes.
     * Would it be enough to move this from a useEffect into the setPartialFitData function?
     */
    const updateLocalStorage = (newData) => {
        // Keep the email private from local storage
        const copiedFitData = { ...newData }
        if (copiedFitData.email) delete copiedFitData.email

        // And then save the rest
        localForage.setItem('fitfinder_data', copiedFitData)
    }



    /**
     * Fetch fit data on mount.
     */
    useEffect(() => {
        let isMounted = true;

        if (isMounted) fetchFitData()

        return () => { isMounted = false }
    }, [fetchFitData])



    return {
        fetching,
        fitData,
        refetch: fetchFitData,
        clearFitData,
        setFitData,
        setPartialFitData
    };
}

export default useFetchFitData