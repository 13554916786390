import React from 'react';
import AccountIcon from '~/images/svg/account.svg'
import { Link } from 'gatsby'

const Account = ({ onClick }) => {
    return (
        <Link to="/account/" className="menu block p-2 md:pr-1.5" aria-label="My Account" onClick={onClick}>
            <AccountIcon className="inline" />
            <span className="hidden md:inline md:ml-3">Account</span>
        </Link >
    )
}

export default Account