module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SuitShop","short_name":"SuitShop","start_url":"/","display":"minimal-ui","icon":"src/images/suitshop-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f1f488e40d9f300308e4a8eb34b6b796"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"suitshop","accessToken":"MC5ZRWJJS2hBQUFDWUFIdFVz.aFdj77-9eu-_ve-_ve-_ve-_vVHvv71nGO-_ve-_ve-_vW4W77-9BSTvv73vv710aEjvv73vv71Iee-_ve-_vQ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
