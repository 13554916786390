import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { getDynamicMegaMenuClasses } from '~/utils/navigation'

import {
  MegaMenuPanel,
  MegaListGrid,
  MegaListGridColumn,
  MegaList,
  MegaListItem,
  MegaLink,
  MegaListTitle,
} from '~/styles/navigation'

const GetStartedMenu = ({ isActive }) => {
  const title = 'GetStarted'
  const menuRef = useRef(null)

  return (
    <MegaMenuPanel
      id={`${title}-panel`}
      aria-labelledby={`${title}-button`}
      className={`${getDynamicMegaMenuClasses(isActive)} py-10`}
    >
      <MegaListGrid className="max-w-[920px] grid-cols-3 gap-4">
        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaListTitle>Start Here</MegaListTitle>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/how-it-works/" ref={menuRef}>
                How It Works
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/store-locations/">Book an Appointment</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/swatches/">Fabric Swatches</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/weddings/">Register a Group</MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <Link className="block" to={`/fit-guide/`}>
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/get_started_size_fit2.jpg"
              width={300}
              quality={100}
              className="object-cover w-full bg-isabelline"
              alt="Size & Fit"
            />
            <span className="inline-block pt-2 hover:underline">
              Size & Fit
            </span>
          </Link>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <a
            className="block"
            href="https://suitshop.gorgias.help/en-US/articles/orders-and-shipping-4135"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/get_started_shipping2.jpg"
              width={300}
              quality={100}
              className="object-cover w-full bg-isabelline"
              alt="Order & Shipping FAQs"
            />

            <span className="inline-block pt-2 hover:underline">
              Order & Shipping FAQs
            </span>
          </a>
        </MegaListGridColumn>
      </MegaListGrid>
    </MegaMenuPanel>
  )
}

export default GetStartedMenu
