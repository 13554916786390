import React from 'react'
import { Link } from "gatsby"

import LogoSVG from '~/images/svg/suitshop-logo.svg'

import {
    LogoContainer,
} from '~/styles/navigation'

const NavLogo = () => {
    return (
        <LogoContainer>
            <Link to="/" tabIndex="0" className="logo max-w-[154px]" >
                <span className="sr-only" > SuitShop </span>
                <LogoSVG className="w-[154px]" />
            </Link>
        </LogoContainer>
    )
}

export default NavLogo