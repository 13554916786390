import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import MobileMenuTitle from '~/components/Navigation/MobileMenu/MobileMenuTitle'

import {
  MobileList,
  MobileListItem,
  MobileLink,
  MobileListTitle,
  MobileDivider,
  MegaExternalLink,
} from '~/styles/navigation'

const MobileResourcesMenu = ({ active, isActive, setActive, routeChange }) => {
  if (isActive) {
    return (
      <>
        <MobileMenuTitle title={active} setActive={setActive} />

        <MobileList>
          <MobileListItem className="mb-1 font-medium text-base">
            <MobileListTitle>Start Here</MobileListTitle>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/contact/" onClick={routeChange}>
              Contact Us
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/returns/" onClick={routeChange}>
              Returns & Exchanges
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/how-to/" onClick={routeChange}>
              How-To Page
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/corporate-services/" onClick={routeChange}>
              Corporate Services
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/about/" onClick={routeChange}>
              About Us
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MegaExternalLink
              href="https://suitshop.gorgias.help/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </MegaExternalLink>
          </MobileListItem>
        </MobileList>

        <MobileDivider />

        <MobileList>
          <MobileListItem className="mb-1 font-medium text-base">
            <MobileListTitle>Contact</MobileListTitle>
          </MobileListItem>
          <MobileListItem>
            <MegaExternalLink
              className="block underline pb-1.5"
              href="tel:17733036006"
            >
              +1 (773) 303-6006
            </MegaExternalLink>
            <span className="block">9am-7pm EST (Mon-Fri).</span>
          </MobileListItem>
          <MobileListItem className="pt-6">
            <MegaExternalLink
              className="block underline pb-1.5"
              href="mailto:info@suitshop.com"
            >
              info@suitshop.com
            </MegaExternalLink>
            <span className="block">
              Our team typically answers withiin 24 hours.
            </span>
          </MobileListItem>
        </MobileList>

        <MobileDivider />

        <div className="pt-3 pb-6 space-y-6">
          <a
            className="block"
            href="https://suitshop.gorgias.help/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/resources_help2.jpg"
              width={358}
              height={233}
              quality={100}
              backgroundColor={`#171C21`}
              className="object-cover bg-[#171C21] w-full h-[221px] md:h-[233px]"
              alt="Help Center"
            />
            <span className="inline-block pt-2 underline">Help Center</span>
          </a>
        </div>
      </>
    )
  }

  return null
}

export default MobileResourcesMenu
