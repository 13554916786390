import React from 'react';
import { Link } from 'gatsby'

const GroupButton = ({ onClick }) => {
    return (
        <Link to="/weddings/" className="menu hidden invisible md:block md:border md:visible border-rich-black bg-rich-black text-white xl:bg-white xl:text-rich-black hover:bg-rich-black hover:text-white h-full py-2 px-6 md:mr-2" onClick={onClick}>
            Suit a group
        </Link>
    )
}

export default GroupButton