import tw from 'twin.macro'
// import styled from '@emotion/styled'
import { Link } from 'gatsby'

import {
    PaddedContainer,
    // Container,
    Content
} from '~/styles/base'

export const NavContainer = tw(PaddedContainer)``
export const NavContent = tw(Content)`flex items-center justify-between h-[60px]`

// Logo
export const LogoContainer = tw.div`mr-auto flex-initial xl:min-w-[295px]`

// Secondary Menu
export const SecondaryMenuContainer = tw.div`ml-auto flex-initial flex items-center justify-end xl:min-w-[295px] md:space-x-1 xl:space-x-2 -mr-2`

// Desktop Menu
export const MenuContainer = tw.div`hidden xl:block flex-1 flex justify-center h-full`
export const NavList = tw.ul`mx-auto text-center`
export const NavListItem = tw.li`inline-block mt-1`

// Mega Menu
export const MegaMenuPanel = tw.div`hidden transition-all lg:block absolute left-0 right-0 top-[59px] z-100 inset-x-0 transform shadow-lg text-left overflow-hidden bg-white`
export const MegaListGrid = tw.div`mx-auto grid`
export const MegaListGridColumn = tw.div`flex justify-center`
export const MegaList = tw.ul`block text-sm space-y-0`
export const MegaListItem = tw.li``
export const MegaLink = tw(Link)`inline-block py-2.5 hover:underline transition-all`
export const MegaExternalLink = tw.a`inline-block py-2.5 hover:underline transition-all`
export const MegaListTitle = tw.span`inline-block py-2.5 mb-2 cursor-default`


// Mobile Menu

export const MobileList = tw(MegaList)``
export const MobileListItem = tw(MegaListItem)``
export const MobileLink = tw(MegaLink)`py-3.5 w-full hover:no-underline`
export const MobileListTitle = tw(MegaListTitle)``
export const MobileDivider = tw.div`h-[1px] w-full my-6 border-b border-[#3E3E3E]`