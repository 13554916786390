import React from 'react'
// import debounce from "debounce"
import tw from 'twin.macro'

import {
    handleToggle,
    handleKey
} from '~/utils/navigation'

const DropdownButton = tw.button`relative cursor-pointer inline-block py-4 xl:px-6 2xl:px-10`
const DropdownUnderline = tw.div`relative top-[3px] w-full h-[1px] bg-rich-black`

const NavDropdown = ({ title, callback, active, children }) => {
    const prettyTitle = title
    title = title.replace(/\s/g, '');
    const isActive = active === title

    return (
        <>
            <DropdownButton
                id={`${title}-button`}
                tabIndex="0"
                type="button"
                onMouseOver={() => handleToggle(callback, title)}
                onKeyDown={(e) => handleKey(e, callback, title, active)}
                aria-controls={`${title}-panel`}
                aria-expanded={isActive}
                className="group"
            >
                <span>{prettyTitle}</span>
                <DropdownUnderline
                    className={`${isActive ? `opacity-100` : `opacity-0`}`}
                />
            </DropdownButton>

            {children}
        </>
    )
}

export default NavDropdown