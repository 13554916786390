import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MobileMenuTitle from '~/components/Navigation/MobileMenu/MobileMenuTitle'

import {
  MobileList,
  MobileListItem,
  MobileLink,
  MobileListTitle,
  MobileDivider,
  MegaExternalLink,
} from '~/styles/navigation'

const MobileWeddingsEventsMenu = ({
  active,
  isActive,
  setActive,
  routeChange,
}) => {
  if (isActive) {
    return (
      <>
        <MobileMenuTitle title={active} setActive={setActive} />

        <MobileList>
          <MobileListItem className="mb-1 font-medium text-base">
            <MobileListTitle>Weddings & Events</MobileListTitle>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/weddings/" onClick={routeChange}>
              Suit a Group
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/swatches/" onClick={routeChange}>
              Fabric Swatches
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/store-locations/" onClick={routeChange}>
              Book an Appointment
            </MobileLink>
          </MobileListItem>
        </MobileList>

        <MobileDivider />

        <MobileList>
          <MobileListItem className="mb-1 font-medium text-base">
            <MobileListTitle>Inspiration</MobileListTitle>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/blogs/real-weddings/" onClick={routeChange}>
              Wedding Inspiration
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MobileLink to="/blogs/news/" onClick={routeChange}>
              The Style Blog
            </MobileLink>
          </MobileListItem>
          <MobileListItem>
            <MegaExternalLink
              href="https://instagram.com/suitshopofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </MegaExternalLink>
          </MobileListItem>
        </MobileList>

        <div className="pt-3 pb-6 space-y-6">
          <Link className="block" to={`/swatches/`} onClick={routeChange}>
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/events_swatches2.jpg"
              width={358}
              height={233}
              quality={100}
              backgroundColor={`#171C21`}
              className="object-cover bg-[#171C21] w-full h-[218px] md:h-[233px]"
              alt="Fabric Swatches"
            />
            <span className="inline-block pt-2 underline">Fabric Swatches</span>
          </Link>

          <Link
            className="block"
            to={`/blogs/real-weddings/`}
            onClick={routeChange}
          >
            <StaticImage
              src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto,w_750,f_auto/v1721498132/gatsby-cloudinary/pages/index/events_weddings2.jpg"
              width={358}
              quality={100}
              backgroundColor={`#171C21`}
              className="object-cover bg-[#171C21] w-full h-[218px] md:h-[233px]"
              alt="Real Weddings"
            />

            <span className="inline-block pt-2 underline">Real Weddings</span>
          </Link>
        </div>
      </>
    )
  }

  return null
}

export default MobileWeddingsEventsMenu
