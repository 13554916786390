import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { mq } from '~/utils/styling'

import MobileSearchBar from './MobileSearchBar'
import DynamicMobileMenu from '~/components/Navigation/MobileMenu/DynamicMobileMenu'
import SecondaryMobileNavigation from './SecondaryMobileNavigation'

import { MobileDivider } from '~/styles/navigation'

const MobileDrawer = styled.div`
  height: calc(100vh - 100px);

  ${mq.md`
        &::-webkit-scrollbar {
            display: none;
        }
    `}
`

const MobileMenu = ({ open, toggleMenu, active, setActive }) => {
  const routeChange = () => {
    setTimeout(() => {
      toggleMenu(false)
    }, 100)
    setTimeout(() => {
      setActive('Main')
    }, 500)
  }

  return (
    <MobileDrawer
      id="mobileDrawer"
      className={`overscroll-none bg-rich-black fixed z-90 ${
        open
          ? `top-[104px]`
          : `-top-[90vh] md:top-[104px] right-0 md:-right-[392px]`
      } overflow-scroll right-0 md:left-auto md:w-[392px] inset-x-0 transition-all transition-300 transform origin-top-right xl:hidden xl:invisible`}
    >
      <div className="bg-rich-black text-white p-4 py-8 pb-24 md:p-8 md:pb-24">
        <MobileSearchBar routeChange={routeChange} />

        {/* {open ? 'open' : 'closed'} */}
        <div className="pt-8 h-full">
          <DynamicMobileMenu
            menuName="Main"
            active={active}
            setActive={setActive}
            routeChange={routeChange}
          />
          <DynamicMobileMenu
            menuName="Shop"
            active={active}
            setActive={setActive}
            routeChange={routeChange}
          />
          <DynamicMobileMenu
            menuName="Get Started"
            active={active}
            setActive={setActive}
            routeChange={routeChange}
          />
          <DynamicMobileMenu
            menuName="Weddings & Events"
            active={active}
            setActive={setActive}
            routeChange={routeChange}
          />
          <DynamicMobileMenu
            menuName="Resources"
            active={active}
            setActive={setActive}
            routeChange={routeChange}
          />
        </div>

        {active === 'Main' ? (
          <>
            <MobileDivider />

            <div className="py-3 space-y-6">
              <Link className="block" to={`/weddings/`} onClick={routeChange}>
                <StaticImage
                  src="https://res.cloudinary.com/the-groomsman-suit/image/upload/q_auto:low,w_750,f_auto/v1721497740/gatsby-cloudinary/pages/index/nav_suit_group2.jpg"
                  width={358}
                  height={233}
                  quality={90}
                  className="object-cover bg-[#171C21] w-full h-[218px] md:h-[233px]"
                  alt="Suit a Group"
                />
                <span className="inline-block pt-2 underline text-base">
                  Suit a Group
                </span>
              </Link>
            </div>

            <MobileDivider />

            {/* TODO: Review bug with contrast accessibility errors */}
            <SecondaryMobileNavigation routeChange={routeChange} />
          </>
        ) : null}
      </div>
    </MobileDrawer>
  )
}

export default MobileMenu
