export const lineItemHasDiscount = item => {
  return (
    item?.discountAllocations[0]?.discountedAmount &&
    item?.discountAllocations[0]?.discountedAmount?.amount > 0
  )
}

export const lineItemDiscountAmount = item => {
  return item?.discountAllocations?.reduce(
    (acc, curr) => acc + curr.discountedAmount.amount,
    0
  )
}

export const lineItemPrice = (item, hasDiscount, discountAmount) => {
  const price = {
    amount: item.merchandise.price.amount,
    currencyCode: item.merchandise.price.currencyCode,
  }

  if (hasDiscount) {
    price.amount -= discountAmount
  }

  return price
}

export const lineItemCompareAtPrice = (item, hasDiscount) => {
  const compareAtPrice = {
    amount: item?.merchandise?.compareAtPrice?.amount ?? 0,
    currencyCode:
      item?.merchandise?.compareAtPrice?.currencyCode ??
      item.merchandise.price.currencyCode,
  }

  if (compareAtPrice && hasDiscount) {
    compareAtPrice.amount = item.merchandise.price.amount
  }

  return compareAtPrice
}
