import React, { useState, useRef } from 'react'
import CloseIcon from '~/images/svg/close.svg'
import tw from 'twin.macro'

export const Overlay = tw.div`fixed inset-0 bg-rich-black`
const CenterUtility = tw.div`flex items-center justify-center min-h-screen`

export const OverlayTransition = ({ open, setOpen, children }) => {
  const handleClick = () => {
    if (open) setOpen(false)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={`${open ? `opacity-100` : `opacity-0`}`}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

export const CloseModalButton = ({ setIsOpen }) => (
  <button
    className="absolute top-2 right-2 lg:top-3.5 lg:right-3"
    onClick={() => setIsOpen(false)}
  >
    <CloseIcon className="h-7 w-7 text-rich-black opacity-30 hover:opacity-90 transition-opacity" />
    <span className="sr-only">Close</span>
  </button>
)

export const Modal = ({ isOpen, setIsOpen, children }) => {
  const [overlayVisible, setOverlayVisible] = useState(false)

  const menuRef = useRef(null)

  React.useEffect(() => {
    let gorgias = document.querySelector('#chat-button')
    isOpen && gorgias ? gorgias?.classList.add('hidden') : gorgias?.classList.remove('hidden')

    const escFunction = e => {
      if (e.key === 'Escape') setIsOpen(false)
    }

    let timer = false

    window.addEventListener('keydown', escFunction)

    if (isOpen) {
      menuRef.current?.focus()
      setOverlayVisible(true)
      document.body.style.overflow = 'clip'
      document.documentElement.style.overflow = 'clip'
      document.body.style.height = 'auto'
      document.documentElement.style.height = 'auto'
    } else {
      timer = setTimeout(() => setOverlayVisible(false), 400)
      menuRef.current?.blur()
      setOverlayVisible(false)
    }

    return () => {
      if (timer) clearTimeout(timer)
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
      window.removeEventListener('keydown', escFunction)
    }
  }, [isOpen, setIsOpen])

  return (
    <div
      className={`overscroll-none transition-opacity duration-200 fixed inset-0 flex z-100 ${
        overlayVisible
          ? `opacity-100 visible`
          : `pointer-events-none opacity-0 invisible`
      }`}
    >
      <div
        className={`absolute inset-0 overflow-hidden mx-0 md:mx-8 lg:mx-8 xl:mx-10 2xl:mx-auto overflow-y-scroll overscroll-none`}
      >
        <CenterUtility>
          <OverlayTransition open={overlayVisible} setOpen={setIsOpen}>
            <Overlay className="opacity-80" />
          </OverlayTransition>

          <div
            aria-modal={isOpen}
            role="dialog"
            className={`w-full md:w-auto transition-all duration-500 ease-in-out z-100 ${
              isOpen ? `opacity-100` : `pointer-events-none opacity-0`
            }`}
            ref={menuRef}
          >
            {children}
          </div>
        </CenterUtility>
      </div>
    </div>
  )
}
