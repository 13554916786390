export const defaultData = {
    anonId: null,
    begunQuiz: 0,
    bodyData: {
        age: '',
        bodyType: '',
        heightFeet: '',
        heightInches: '',
        jacketSize: '',
        shoeSize: '',
        waistCircumference: '',
        weight: '',
    },
    completedQuiz: 0,
    currentStep: 0,
    email: '',
    emailKey: null,
    entryPoint: null,
    errorMessages: [],
    errorStatus: false,
    fitType: 'men',
    message: '',
    outlierMessages: [],
    outlierStatus: false,
    recommendations: {
        men: {
            jacket: [],
            pant: []
        },
        women: {
            jacket: [],
            pant: []
        }
    }
}



/**
 * The default error messages to display when an error occurs.
 *
 * @type {Array.<object>}
 */
export const defaultErrorMessages = [
    {
        message: 'An unknown error occurred. Please try again later.'
    }
]



/**
 * Get the equivalent size in the opposite fit type.
 *
 * We can refactor this when we send the size & length in the recommendation
 * object along with the fitType (there already). That way we don't have to
 * split the prediction string like we are now. — pandora
 *
 * @param {String} garment The garment type to get the equivalent size for.
 * @param {String} prediction The prediction to get the equivalent size for.
 * @return {String} The equivalent size.
 */
export const getEquivalentSize = (garment, prediction) => {
    const equivalencies = {
        'jacket': {
            'Slim': {
                'fit': 'Modern',
                'size': -2
            },
            'Modern': {
                'fit': 'Slim',
                'size': +2
            }
        },
        'pant': {
            'Slim': {
                'fit': 'Modern',
                'size': -1
            },
            'Modern': {
                'fit': 'Slim',
                'size': +1
            }
        }
    }

    // 0: Fit, 1: Size, 2: Length (we won't use length for a new calculation)
    const predictionParts = prediction?.split(' / ')
    if (predictionParts?.length > 0) {
        const equivalent = equivalencies[garment][predictionParts[0]]
        const newSize = parseInt(predictionParts[1]) + equivalent.size

        return `${equivalent.fit} / ${newSize} / ${predictionParts[2]}`
    }

    return prediction
}



/**
 * Returns the field names for a given step.
 *
 * @param {Number} step The step number to get the fields for.
 * @return {Array.<string>} The array of field names for the step.
 */
export const getFieldsByStep = (step) => {
    const fields = {
        1: ['fitType'],
        2: ['bodyData_heightFeet', 'bodyData_heightInches', 'bodyData_weight'],
        3: ['bodyData_waistCircumference', 'bodyData_jacketSize'],
        4: ['bodyData_age', 'bodyData_shoeSize'],
        5: ['bodyData_bodyType'],
        6: [],
        7: ['email']
    }

    return fields[step]
}



/**
 * Returns the height in feet and inches from the height in inches.
 *
 * @param {Number} heightInInches The height in inches.
 * @return {Object} The height in feet and inches.
 */
export const getHeightInImperial = (heightInInches) => {
    return {
        heightFeet: Math.floor(heightInInches / 12),
        heightInches: heightInInches % 12
    }
}



/**
 * Returns the height in inches from the two discrete height inputs.
 *
 * @param {Number} heightFeet The height in feet.
 * @param {Number} heightInches The height in inches.
 * @return {Number} The height in inches.
 */
export const getHeightInInches = (heightFeetData, heightInchesData) => {
    const heightFeet = parseInt(heightFeetData) || 0;
    const heightInches = parseInt(heightInchesData) || 0;
    return (heightFeet * 12) + heightInches;
}



/**
 * Returns necessary recommendation content for the fit results page.
 * Based on the fit type, garment piece, and prediction string.
 *
 * @param {String} fitType The fit type (men or women)
 * @param {String} garment The garment piece (jacket or pant)
 * @param {Object} recommendation The recommendation object.
 * @return {Object} The recommendation content object.
 */
export const getRecommendationContent = (fitType, garment, recommendation) => {
    const contents = {
        'mens_jacket': {
            'slim': {
                'eyebrow': 'Jacket Recommendation',
                'summary': 'Ideal for your broad shoulders, V-shaped torso, or narrow frame up top.',
                'education': {
                    'title': 'Doesn\'t sound like your body type?',
                    'body': 'Modern Fit will have the same close-cut, sleek look but for a straight torso or to allow more room around the midsection. <strong>To swap</strong> to a Modern jacket, go down 1 size (2 numbers) to a <span style="white-space: nowrap;">[size]</span>.'
                }
            },
            'modern': {
                'eyebrow': 'Jacket Recommendation',
                'summary': 'Ideal for your straight torso or to allow more room around the midsection.',
                'education': {
                    'title': 'Doesn\'t sound like your body type?',
                    'body': 'Slim Fit will have the same sleek look but for an especially narrow torso, V-shaped waist, or broad shoulders. <strong>To swap</strong> to a Slim jacket, go up 1 size (2 numbers) to a <span style="white-space: nowrap;">[size]</span>.'
                }
            }
        },
        'mens_pant': {
            'slim': {
                'eyebrow': 'Pant Recommendation',
                'summary': 'Intended for your specific waist size or more slender leg shape.',
                'education': {
                    'title': 'Not the fit for you?',
                    'body': 'Modern Fit will have the same trim and tailored look but with some extra room for more muscular legs or larger frames. <strong>To switch</strong> into Modern pants, go down 1 size number to a <span style="white-space: nowrap;">[size]</span>.'
                }
            },
            'modern': {
                'eyebrow': 'Pant Recommendation',
                'summary': 'Intended for your specific waist size, muscular legs, or larger build.',
                'education': {
                    'title': 'Not the fit for you?',
                    'body': 'Slim Fit will have the same trim and tailored look but cut for more slender legs. <strong>To switch</strong> into Slim pants, go up 1 size number to a <span style="white-space: nowrap;">[size]</span>.'
                }
            }
        }
    }

    const cut = recommendation?.fitType?.toLowerCase() ?? 'N/A'

    return contents[`${fitType}s_${garment}`][cut] ?? null
}



/**
 * Returns the error status from the response data.
 *
 * @param {Object} responseData The response data from the API.
 * @return {Boolean} The error status.
 */
export const getResponseErrorStatus = (responseData) => {
    return responseData?.error_messages?.length > 0 || typeof responseData === 'undefined'
}



/**
 * Returns what currentStep we should go to based on the response data.
 *
 * @param {Object} responseData The response data from the API.
 * @return {Number} The step number to go to.
 */
export const getResponseStep = (responseData) => {
    if (responseData?.error_messages?.length > 0 || responseData?.outlier_status) {
        return 6
    }

    return 7
}



/**
 * Returns the rounded weight.
 *
 * @param {Number} weight The weight to round.
 * @return {Number} The rounded weight.
 */
export const getRoundedWeight = (weight) => {
    const min = 97
    const max = 460

    function round(x, ceil = false) {
        return ceil ? Math.ceil(x / 5) * 5 : Math.round(x / 5) * 5;
    }

    let rounded = round(weight)

    if (rounded < min) rounded = round(min, true)
    if (rounded > max) rounded = max

    return rounded
}



/**
 * Deduplicates an array of message objects by the message content.
 *
 * @param {Array.<object>} messageObjects The array of message objects to deduplicate.
 * @return {Array.<object>} The deduplicated array of message objects.
 */
export const getUniqueMessages = (messageObjects) => {
    return messageObjects.map(messageObject => (
        {
            ...messageObject,
            message: readableFitKeys(messageObject.message)
        }
    )).filter((messageObject, index, self) => (
        index === self.findIndex((t) => (t.message === messageObject.message)) && messageObject.message.length > 0
    ))
}



/**
 * Returns the step number for a given field.
 *
 * @param {String} field The field to get the step for.
 * @return {Number} The step number.
 */
export const getStepByField = (fieldName) => {
    const steps = {
        'fitType': 1,
        'bodyData_heightFeet': 2,
        'bodyData_heightInches': 2,
        'bodyData_weight': 2,
        'bodyData_waistCircumference': 3,
        'bodyData_jacketSize': 3,
        'bodyData_age': 4,
        'bodyData_shoeSize': 4,
        'bodyData_bodyType': 5,
        'email': 7,
        'waist_circum_stomach': 3,
        'waist_circum_preferred': 3,
    }

    return steps[fieldName] ?? 1
}



/**
 * Translates a string message to replace the API's keys with
 * human readable values.
 *
 * @param {String} message The message string to translate.
 * @return {String} The translated message string.
 */
const readableFitKeys = (message) => {
    const deletions = [
        // 'waist_height_preferred',
        // 'jean_inseam',
        // 'sleeve_inseam'
    ];

    const replacements = {
        'waist_circum_preferred': 'Pant Waist',
        'waist_circum_stomach': 'Pant Waist',
        'jean_inseam': 'estimated Jean Inseam',
        'sleeve_inseam': 'estimated Sleeve Inseam',
        'waist_height_preferred': 'estimated Waist Height',
        'jacket_size': 'Jacket Size',
        'age': 'Age',
        'shoe_size_us': 'Shoe Size',
        'height': 'Height',
        'weight': 'Weight',
        'body_type': 'Body Type',
        'fitting_type': 'Fit Type',
        'email': 'Email'
    }

    // Remove keys that have string that shows up in the deletions array
    deletions.forEach(deletion => {
        if (message.includes(deletion)) message = ''
    })

    return Object.keys(replacements).reduce((acc, key) => {
        return acc.replace(new RegExp(key, 'g'), replacements[key]);
    }, message);
}



/**
 * Scrolls the page to the Fit Finder component.
 *
 * @return {void}
 */
export const scrollFitPage = () => {
    document?.querySelector('#fitfinder')?.scrollIntoView({
        block: "start",
        behavior: "instant"
    })
}



/**
 * Translate the bodyData object key value pairs to new keys with the bodyData prefix.
 * Example: {heightFeet: 5} => {bodyData_heightFeet: 5}
 *
 * @param {Object} bodyData
 * @return {Object} The new object with the renamed keys.
 */
export const translateBodyDataContextToFormikValues = (bodyData) => {
    return Object.keys(bodyData).reduce((acc, key) => {
        acc[`bodyData_${key}`] = bodyData[key];
        return acc;
    }, {})
}



/**
 * Translates the Formik values object to a new object with the
 * keys renamed to match the API's expected keys.
 *
 * @param {Object} values The Formik values object.
 * @return {Object} The data submission object.
 */
export const translateFormikValuesForSubmission = (values) => {
    values.bodyData_height = getHeightInInches(values.bodyData_heightFeet, values.bodyData_heightInches);

    // An object to map the values keys to the API's expected keys
    const keyTranslations = {
        'anonId': 'uuid',
        'fitType': 'fitting_type',
        'email': 'email',
        'subscribeToEmail': 'subscribe_to_email',
        'bodyData_height': 'height',
        'bodyData_weight': 'weight',
        'bodyData_waistCircumference': 'waist_circum_preferred',
        'bodyData_jacketSize': 'jacket_size',
        'bodyData_age': 'age',
        'bodyData_shoeSize': 'shoe_size_us',
        'bodyData_bodyType': 'body_type'
    }

    // Translate the values object to the API's expected keys and return it
    return Object.keys(values).reduce((acc, key) => {
        if (keyTranslations[key]) {
            acc[keyTranslations[key]] = values[key];
        }
        return acc;
    }, {});
}



/**
 * Translates the Formik values object to a new object with the
 * bodyData fields renamed and moved to the bodyData object.
 * Example: {bodyData_heightFeet: 5} => {bodyData: {heightFeet: 5}}
 *
 * @param {Object} values The Formik values object.
 * @return {Object} The new values object.
 */
export const translateFormikValuesToContext = (values) => {
    return Object.keys(values).reduce((translatedValues, key) => {
        if (key.startsWith('bodyData_')) {
            const newKey = key.replace('bodyData_', '');
            translatedValues.bodyData = {
                ...translatedValues.bodyData,
                [newKey]: values[key]
            };
        } else {
            translatedValues[key] = values[key];
        }
        return translatedValues;
    }, {});
}



/**
 * Validates the recommendations object to ensure it has the correct
 * structure.
 *
 * @param {Object} recommendations The recommendations object.
 * @param {String} fitType The fit type.
 * @return {Boolean} The validation status.
 */
export const validateRecommendations = (recommendations, fitType) => {
    return (
        (
            recommendations[fitType]?.jacket?.length > 0 &&
            typeof recommendations[fitType]?.jacket?.[0]?.prediction === 'string'
        ) && (
            recommendations[fitType]?.pant?.length > 0 &&
            typeof recommendations[fitType]?.pant?.[0]?.prediction === 'string'
        )
    )
}