import React from 'react';
import NavCloseIcon from '~/images/svg/nav_close.svg'

const NavClose = ({ onClick }) => {
    return (
        <button aria-label="Close menu" className="menu-close block xl:hidden xl:invisible p-2 mt-[3px]" onClick={onClick}>
            <span className="sr-only">Close menu</span>
            <NavCloseIcon />
        </button>
    )
}

export default NavClose