import React from 'react'

const MobileMenuTitle = ({ title, setActive }) => {
    return (
        <button aria-label="Return to main menu" className="mb-4 block w-full py-3 border-b border-[#3E3E3E] text-center relative" onClick={() => setActive('Main')}>
            <BackArrow />

            {title}
        </button>
    )
}

const BackArrow = () => (
    <svg className="left-0 bottom-4 absolute" xmlns="http://www.w3.org/2000/svg" width="6.183" height="11.366" viewBox="0 0 6.183 11.366">
        <path d="M9.952,0,4.976,4.976,0,0" transform="translate(5.476 0.707) rotate(90)" fill="rgba(0,0,0,0)" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </svg>
)

export default MobileMenuTitle