import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { Formik, Form, Field } from 'formik'
import FacebookIcon from '~/images/svg/facebook.svg'
import PinterestIcon from '~/images/svg/pinterest.svg'
import TwitterIcon from '~/images/svg/twitter.svg'
import InstagramIcon from '~/images/svg/instagram.svg'
import axios from 'redaxios'
import * as yup from 'yup'

import { PaddedContainer, Content } from '~/styles/base'

const EmailSignupForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = React.useState(false)

  const submitForm = (values, actions) => {
    axios({
      method: 'POST',
      url: `${process.env.GATSBY_ADMIN_ENDPOINT}/api/newsletter/subscribe`,
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false)
        if (response?.data !== 'undefined' && response?.data === 'Success') {
          setSubmitted(true)
          if (typeof window.ttq === `object`) {
            window.ttq.identify({
              email: values?.email ?? null,
            })
            window.ttq.track('Subscribe', {
              description: 'Footer Newsletter Signup',
            })
          }
          if (typeof window.fbq === `function` && window.fbq !== null) {
            window.fbq('init', '398641100491036', {
              em: values?.email ?? null,
            })
            window.fbq('track', 'Lead', {
              content_name: 'Footer Newsletter Signup',
            })
          }
        } else {
          // console.log(response)
          setSubmitted(true)
          setError(true)
        }
      })
      .catch(error => {
        actions.setSubmitting(false)
        if (error.status === 422 && typeof error.data.errors !== 'undefined') {
          console.log(error.data)
          actions.setErrors(error.data.errors)
        } else {
          console.log(error.data)
          setError(true)
          setSubmitted(true)
        }
      })
  }

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    listId: yup.string(),
  })

  return (
    <Formik
      initialValues={{
        email: '',
        listId: 'XdE2Ty',
      }}
      onSubmit={submitForm}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors, touched, setFieldValue }) => (
        <Form>
          <>
            <label htmlFor="email" className="sr-only">
              Sign up for emails
            </label>
            <Field
              id="email"
              placeholder="Enter email address"
              type="email"
              className="appearance-none rounded-none border border-[#9A9A9A] text-dim-gray p-3 px-4 text-sm w-[75%] xl:w-[70%] border-r-0 h-[50px]"
              aria-invalid={touched.email && errors.email}
              aria-errormessage="emailErr"
              name="email"
            />
            <button
              type="submit"
              className="w-[25%] xl:w-[30%] font-mono text-sm uppercase tracking-wide !bg-rich-black text-white p-3 px-2 border border-rich-black rounded-none h-[50px]"
              aria-label="Submit"
              disabled={isSubmitting || submitted}
            >
              <span className="font-mono">
                {isSubmitting ? `...` : `Submit`}
              </span>
            </button>
          </>
          {touched.email && errors.email && (
            <div
              id="emailErr"
              className="block text-sm text-red-700 w-auto mt-4 lg:mt-2"
            >
              {errors.email}
            </div>
          )}

          {submitted && !error && (
            <div className="block text-sm text-green-700 w-auto mt-4 lg:mt-2">
              You've successfully joined the mailing list.
            </div>
          )}

          {submitted && error && (
            <div className="block text-sm text-red-700 w-auto mt-4 lg:mt-2">
              An error occurred. Please{' '}
              <Link to="/contact/" className="underline">
                contact us
              </Link>
              .
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
const Footer = () => {
  return (
    <PaddedContainer id="footer">
      <Content>
        <div className="border-t border-b border-[#96999d] py-12 md:py-24">
          <div className="grid grid-cols-16">
            {/* LOGO */}
            <div className="order-2 md:order-1 mt-14 md:mt-0 col-span-16 md:col-span-6 xl:col-span-3">
              <StaticImage
                src={`../images/suitshop-logo.png`}
                alt={`SuitShop`}
                width={164}
              />

              <ul className="space-x-4 mt-5 xl:mt-8">
                <li className="inline-block">
                  <a
                    aria-label="Visit SuitShop on Facebook"
                    href="https://facebook.com/suitshopofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon
                      className="text-[#9a9a9a] hover:text-rich-black transition-colors w-5 h-5 m-0"
                      role="img"
                      aria-label="Facebook icon"
                    />
                  </a>
                </li>
                <li className="inline-block">
                  <a
                    aria-label="Visit SuitShop on Pinterest"
                    href="https://pinterest.com/suitshopofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PinterestIcon
                      className="text-[#9a9a9a] hover:text-rich-black transition-colors w-5 h-5 m-0"
                      role="img"
                      aria-label="Pinterest icon"
                    />
                  </a>
                </li>
                <li className="inline-block">
                  <a
                    aria-label="Visit SuitShop on Instagram"
                    href="https://instagram.com/suitshopofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon
                      className="text-[#9a9a9a] hover:text-rich-black transition-colors w-5 h-5 m-0"
                      role="img"
                      aria-label="Instagram icon"
                    />
                  </a>
                </li>
                <li className="inline-block">
                  <a
                    aria-label="Visit SuitShop on Twitter"
                    href="https://twitter.com/wearesuitshop"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon
                      className="text-[#9a9a9a] hover:text-rich-black transition-colors w-5 h-5 m-0"
                      role="img"
                      aria-label="Twitter icon"
                    />
                  </a>
                </li>
              </ul>
            </div>

            {/* LINKS */}
            <div className="order-3 mt-12 md:mt-20 xl:mt-0 xl:order-2 col-span-16 xl:col-span-9">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-3">
                  <p className="font-medium mb-4">Customer Service</p>

                  <ul className="text-sm text-dim-gray mt-8">
                    <li>
                      <Link className="hover:underline" to="/returns/">
                        Returns & Exchanges
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/account/">
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/store-locations/">
                        Store Locations
                      </Link>
                    </li>
                    <li>
                      <a
                        className="hover:underline"
                        href="https://suitshop.gorgias.help/en-US"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help Center
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <p className="font-medium mb-4">Company</p>

                  <ul className="text-sm text-dim-gray mt-8">
                    <li>
                      <Link className="hover:underline" to="/about/">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/reviews/">
                        Reviews
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/insiders/">
                        SuitShop Insiders
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/how-it-works/">
                        How It Works
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/blogs/news/">
                        News & Events
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/sustainability/">
                        Sustainability
                      </Link>
                    </li>
                    <li>
                      <Link className="hover:underline" to="/careers/">
                        Careers
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <p className="font-medium mb-4">Contact Us</p>

                  <ul className="text-sm text-dim-gray mt-8">
                    <li>
                      <Link className="hover:underline" to="/contact/">
                        Get In Touch
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:underline"
                        to="/corporate-services/"
                      >
                        Corporate Services
                      </Link>
                    </li>
                    <li>+1 (773) 303-6006</li>
                    <li>info@suitshop.com</li>
                  </ul>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <p className="font-medium mb-4">Legal</p>

                  <ul className="text-sm text-dim-gray mt-8">
                    <li>
                      <Link
                        className="hover:underline"
                        to="/terms/terms-of-service/"
                      >
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:underline"
                        to="/terms/privacy-policy/"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="#" className="iubenda-cs-preferences-link">
                        Privacy Preferences
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* EMAIL ADD */}
            <div className="order-1 col-span-16 md:col-span-10 md:order-2 xl:order-3 xl:col-span-4">
              <div className="xl:border-l xl:border-[#96999d] lg:pl-10 2xl:pl-14 xl:min-h-[260px]">
                <p className="font-medium mb-4 hidden xl:block">
                  Add your email
                </p>
                <p className="font-medium mb-3 xl:hidden">Sign up for emails</p>

                <p className="hidden xl:block my-8 text-dim-gray text-sm">
                  Sign up to received exclusive SuitShop news, updates, deals,
                  and more!
                </p>

                <EmailSignupForm />
              </div>
            </div>
          </div>
        </div>
        <div className="py-6 pt-8">
          <p className="text-sm text-rich-black opacity-60">
            © Copyright {new Date().getFullYear()} SuitShop
          </p>
        </div>
      </Content>
    </PaddedContainer>
  )
}

export default Footer
