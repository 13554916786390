import React from 'react'
import { defaultData } from '~/components/FitFinder/utils'


const FitFinderContext = React.createContext({
    ...defaultData,
    fetchingFitData: true,
    goToBeginning: () => { },
    refetchFitData: () => { },
    reviewing: false,
    startOver: () => { },
    startQuiz: () => { },
    submit: () => { },
    stepCounts: {
        'men': 7,
        'women': 0
    }
})

export default FitFinderContext